import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
    name: "unauthorized",
    metaInfo: {
        title: "Access denied",
    },
})
export default class Unauthorized extends Vue {
    public get isAuthenticated(): boolean {
        return this.$store.getters["account/isAuthenticated"];
    }

    public async login():Promise<void>{
        await this.$store.dispatch("account/logout");
    }
}
